<template>
    <router-view/>
</template>

<style lang="scss">
@forward 'vuetify/settings' with (
  $utilities: false,
);

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
</style>
