<template>

  <!-- Hero Section -->
  <section
    class="py-24 text-white"
    :style="{ background: `linear-gradient(rgba(7, 30, 66, 0.55), rgba(7, 30, 66, 0.55)), url('/images/${eventId}/hero.webp') no-repeat center`, backgroundSize: 'cover' }"
  >
    <div class="container px-4 mx-auto">
      <div class="flex flex-col justify-center items-center">
        <img class="w-[82px] lg:w-[180px]" src="/images/all/logos/tracx-logo-white.svg" alt="tracx logo">
        <h1 class="mt-8 text-white lg:mt-12 lg:text-6xl">Beneva Cheerscreen</h1>
        <p class="text-xl mb-8 text-center lg:text-2xl">
          Support your running hero with a personal photo message <span class="inline-block">during the Beneva Mississsauga Marathon and Half Marathon.</span>
        </p>
        <a href="#form" class="btn">Upload photo message</a>
        <img class="mt-12 w-[82px] lg:w-[180px]" :src="'/images/' + eventId + '/logos/beneva-mississauga.svg'" alt="Beneva Mississauga logo">
      </div>
    </div>
  </section>

  <div class="container px-4 mx-auto">

    <!-- Video Upload Section -->
    <section class="max-w-[1200px] mx-auto">
      <div class="flex flex-col items-center my-10 lg:my-16">
        <h2 class="text-center max-w-[600px] lg:text-4xl lg:mb-8">Boost your friends’ spirits as they take on the Beneva Mississauga Marathon event!</h2>
        <p class="text-center text-blue lg:text-xl max-w-[800px]">
          With a record number of participants expected at the Marathon in Mississauga, whether or not you can be there in person to support, you can still make an impact. Submit your personal message below to be displayed for the marathon, half marathon and 10k live on the big screen as your friend, colleague, or family member runs by. For those in the 5K messages will the messages be sent afterwards. How cool is that?
        </p>
      </div>

      <div class="grid grid-cols-1 gap-8 lg:grid-cols-2">
        <div>
          <p class="font-semibold mb-6 text-xl">Preview</p>
          <div class="rounded-lg bg-white aspect-square max-w-[500px] relative radiant">
            <img class="rounded-lg w-full h-full object-cover absolute top-0 right-0 z-0" :src="previewImage ?? '/images/all/cheerscreen-photo-backdrop.png'" alt="">
            <img class="absolute right-[20px] top-[28px] z-20 w-[125px]" :src="'/images/' + eventId + '/logos/beneva.svg'" alt="">
            <img class="absolute left-[20px] top-[20px] z-20 w-[125px]" :src="'/images/' + eventId + '/logos/beneva-mississauga.svg'" alt="">
            <p class="absolute text-4xl font-semibold w-[450px] z-20 break-words text-white"
               :class="formData.image ? 'left-[20px] bottom-[66px]' : 'left-[50%] bottom-[50%] translate-x-[-50%] translate-y-[50%] text-center'"
            >{{ formData.message }}</p>
            <p class="absolute left-[20px] bottom-[20px] text-3xl z-20 font-semibold mt-2 text-white">- {{ formData.name_cheerer }}</p>
          </div>
        </div>

        <template v-if="isSubmitted">
          <div><p class="text-orange font-semibold">Thank you for your (Cheer) message! This will surely help your running hero reach the finish line!</p>
            <button class="btn inline-flex mt-3"  @click.prevent="refreshAndNavigate">Upload another photo message</button></div>
        </template>
        <template v-else>
          <div id="form" class="flex flex-col">
            <p class="font-semibold mb-6 text-xl">Upload a photo</p>

            <!-- Upload Form -->
            <v-form ref="form" validate-on="submit" @submit.prevent="submitForm">
              <v-row>
                <v-col cols="12" class="!pb-0">
                  <v-text-field
                    v-model="formData.name_participant"
                    :rules="requiredRules"
                    label="Name of the participant (required)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="!pb-0">
                  <v-text-field
                    v-model="formData.start_number"
                    :rules="requiredRules"
                    label="Bib Number of participant (required)"
                    class="no-message"
                  >
                  </v-text-field>
                  <p class="leading-2 font-weight-light text-sm text-gray-600 px-4 mb-4"><a
                    class="text-orange underline" target='blank' :href="'https://tracx.events/nl/events/' + eventId">Bib numbers overview</a>
                  </p>
                </v-col>
                <v-col cols="12" class="!pb-0">
                  <v-file-input
                    v-model="formData.image"
                    :rules="imageRules"
                    label="Upload your photo (required)"
                    @update:modelValue="previewSelectedImage"
                    class="overflow-hidden"
                  ></v-file-input>
                  <p class="leading-2 font-weight-light text-sm text-gray-600 px-4 mb-4">
                    Allowed file types: jpg, jpeg, png, Max. file size: 5 MB.
                  </p>
                </v-col>
                <v-col cols="12" class="!pb-0">
                  <v-text-field
                    v-model="formData.name_cheerer"
                    :rules="requiredRules"
                    :counter="25"
                    :maxlength="25"
                    label="Your name (required)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="!pb-0">
                  <v-text-field
                    v-model="formData.message"
                    :rules="requiredRules"
                    :counter="50"
                    :maxlength="50"
                    label="Cheer message (required)"
                  ></v-text-field>
                  <p class="leading-2 font-weight-light text-sm text-gray-600 px-4 mb-4">
                    Max. 50 characters
                  </p>
                </v-col>
                <v-col cols="12" class="!pb-0">
                  <v-checkbox
                    v-model="formData.terms"
                    :rules="termsRules"
                    label="I agree that my photo will be shown on the big screen and that it can be used by Sportunity for marketing purposes."
                    class="no-message"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-btn class="btn mt-6" variant="flat" :ripple=false type="submit" ref="submitButton" :loading="loading">
                Send
              </v-btn>
            </v-form>
          </div>
        </template>
      </div>
    </section>
  </div>

  <!-- Map Section -->
  <section class="flex flex-col bg-blue mt-40 text-white lg:mt-72 pb-8">
    <div class="container px-4 mx-auto">
      <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-8">
        <div class="flex flex-col lg:order-2">
          <img class="block -mt-24 lg:-mt-40" alt="Route map" :src="'/images/' + eventId + '/map.webp'">
        </div>

        <div class="flex flex-col mt-8 lg:order-1 lg:mt-14">
          <h3 class="text-white">Support your hero to the finish line too!</h3>
          <p class="text-gray-300 text-sm lg:text-base">Upload your photo to show your personal encouragement live on the big screen as your friend or family member runs through the TRACX Cheer Zone during the marathon.</p>
          <a class="block pt-10 lg:py-10 lg:mt-auto" href="https://tracx.events/" target="_blank">
            <img class="w-full max-w-[170px]" alt="Route map" src="/images/all/logos/tracx.png">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "@/firebase";
import {createSubmission, auth} from "@/firebase";
import {FFmpeg} from '@ffmpeg/ffmpeg';
import {fetchFile} from '@ffmpeg/util';


export default {
  name: 'formPage',
  data: () => {
    const eventId = "1394";

    return {
      eventId,
      isSubmitted: false,
      loading: false,
      previewImage: null,
      formData: {
        event_id: eventId,
        name_participant: "",
        start_number: "",
        name_cheerer: "",
        message: "",
        image: null,
        terms: false,
      },
      requiredRules: [
        value => {
          if (value) {
            return true
          }

          return 'This field is required';
        }
      ],
      emailRules: [
        value => {
          if (value) {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
              return true;
            } else {
              return 'This is not a valid email address.';
            }
          }

          return 'This field is required';
        }
      ],
      termsRules: [
        value => {
          if (value) {
            return true
          }

          return 'This field is required';
        }
      ],
      imageRules: [
        value => {
          if (value && value.length > 0) {
            const imageFile = value[0];

            const allowedTypes = ['image/jpeg', 'image/png'];
            if (!allowedTypes.includes(imageFile.type)) {
              return 'The file must be an image (jpg, jpeg, or png).';
            }

            // Controleer bestandsgrootte (maximaal 5 MB)
            const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
            if (imageFile.size > maxFileSize) {
              return 'The image must not be larger than 5 MB.';
            }

            return true;
          }

          return true;
        }
      ]
    }
  },
  methods: {
    previewSelectedImage() {
      if (this.formData.image) {
        const file = this.formData.image;
        this.previewImage = URL.createObjectURL(file);
      } else {
        this.previewImage = null;
        this.formData.image = null;
      }
    },
    refreshAndNavigate() {
      window.location.href = '/' + this.eventId + '/upload#form';
      window.location.reload();
    },
    async submitForm() {
      this.loading = true;
      const { valid } = await this.$refs.form.validate();

      if (!valid) return this.loading = false;

      const uploadFile = async () => {
        if (!this.formData.image) return;

        const file = this.formData.image;
        const fileName = `${auth.currentUser.uid}-${file.name}`;
        const storageRef = ref(storage, `uploads/${this.eventId}/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed", (snapshot) => {
          console.log("Uploading...", `${(snapshot.bytesTransferred / snapshot.totalBytes) * 100}%`);
        }, (error) => {
          console.error("Upload failed:", error);
          this.loading = false;
        });

        return new Promise((resolve, reject) => {
          uploadTask.on("state_changed", null, null, () => {
            getDownloadURL(uploadTask.snapshot.ref).then(resolve).catch(reject);
          });
        });
      };

      try {
        if (this.formData.image) {
          this.formData.image = await uploadFile();
        }

        const result = await createSubmission(this.formData);
        if (result) this.isSubmitted = true;

      } catch (error) {
        console.error("Error:", error);
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>


<style scoped lang="scss">

body {
  background: #FAFBFC;
}

.radiant:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7315519957983193) 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 0 0 0.5rem 0.5rem;
}
</style>
