import {createRouter, createWebHistory} from 'vue-router';
import * as benevaFormPage from "@/views/1394/form-page";
// import * as benevaPhotosPage from "@/views/1394/photos-page";
import * as midwinterFormPage from "@/views/1314/form-page";
import * as midwinterVideosPage from "@/views/1314/videos-page";

const routes = [
  {
    path: '/',
    redirect: '/1394/upload'
  },
  {
    path: '/beneva-mississauga',
    redirect: '/1394/upload'
  },
  // {
  //   path: '/1394/photos',
  //   component: benevaPhotosPage.default,
  //   meta: {
  //     title: 'Cheerzone - Beneva Mississauga videos'
  //   }
  // },
  {
    path: '/1394/upload',
    component: benevaFormPage.default,
    meta: {
      title: 'Cheerzone - Beneva Mississauga upload'
    }
  },
  {
    path: '/midwinter',
    redirect: '/1314/videos'
  },
  {
    path: '/1314/videos',
    component: midwinterVideosPage.default,
    meta: {
      title: 'Cheerzone - Midwinter videos'
    }
  },
  {
    path: '/1314/upload',
    component: midwinterFormPage.default,
    meta: {
      title: 'Cheerzone - Midwinter upload'
    }
  }
];

const router = new createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

export default router;
