import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {
  doc, getDoc, addDoc, collection, getFirestore, query, where, getDocs, connectFirestoreEmulator
} from "firebase/firestore";
import {
  getAuth, signInAnonymously, connectAuthEmulator
} from "firebase/auth";
import {
  getStorage, ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator
} from "firebase/storage";
import { testData } from './test-data.js';

const firebaseConfig = {
  apiKey: "AIzaSyDoa6rnk5YBsgNP7redUsvbi72rGMFGtPo",
  authDomain: "cheerzone-5eb66.firebaseapp.com",
  projectId: "cheerzone-5eb66",
  storageBucket: "cheerzone-5eb66.firebasestorage.app",
  messagingSenderId: "248225192430",
  appId: "1:248225192430:web:b43f85c4723946fb7196ff",
  measurementId: "G-CML4BHQ1Q7"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

if (process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 1200);
  connectStorageEmulator(storage, "localhost", 9199);
}

await signInAnonymously(auth);

export {auth, db, storage}

export async function createSubmission(formData) {
  try {
    await addDoc(collection(db, 'submissions'), formData);

    return true;
  } catch (error) {
    console.error("Error creating document: ", error);
    return false;
  }
}

export async function importTestData() {
    // Doorloop alle records in testData
    for (const [id, data] of Object.entries(testData)) {
      await createSubmission(data);
    }
}

// importTestData();

export async function getSubmissionByStartNumber(startNumber, eventId = null) {
    try {
        // Create a query against the 'submissions' collection
      const baseQuery = query(
        collection(db, "submissions"),
        where("start_number", "==", startNumber)
      );

      const q = eventId
        ? query(baseQuery, where("event_id", "==", eventId))
        : baseQuery;

        // Execute the query
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.log("No matching documents found!");
            return [];
        } else {
          let foundData = [];
          querySnapshot.forEach((doc) => {
            let data = doc.data();
            data.id = doc.id;
            foundData.push(data);
          });

          return foundData;
        }
    } catch (error) {
        console.error("Error retrieving document: ", error);
    }
}
//
// // Call the function with the specific start number
// getSubmissionByStartNumber("123");


