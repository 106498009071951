<template class="bg-[#fafbfb]">
  <section class="text-white pt-16 lg:pt-24 pb-[200px] lg:pb-[220px]"
           :style="{ background: `linear-gradient(rgba(7, 30, 66, 0.55), rgba(7, 30, 66, 0.55)), url('/images/${eventId}/hero.webp') no-repeat center`, backgroundSize: 'cover' }"
  >
    <div class="container px-4 mx-auto">
      <div class="flex flex-col justify-center items-center">
        <img class="w-[82px] lg:w-[180px]" src="/images/all/logos/tracx-logo-white.svg" alt="tracx logo">
        <h1 class="mt-8 text-white lg:mt-12 lg:text-6xl"><span class="italic">TRACX</span> #CheerZone</h1>
        <p class="text-xl mb-8 lg:text-2xl">
          Jouw Midwinter Marathon aanmoedigingsvideos
        </p>
        <img class="mt-12 w-[82px] lg:w-[180px]" :src="'/images/' + eventId + '/logos/midwinter-logo.webp'" alt="Midwinter marathon logo">
      </div>
    </div>
  </section>

  <div class="container px-[40px] mx-auto shadow-lg max-w-[910px] bg-white -mt-[100px] md:rounded pt-20">
    <h2 class="mb-12 text-center text-4xl">Zoek jouw video</h2>
    <form class="w-full flex justify-center mb-20" @submit.prevent="searchVideo" id="search">
      <div class="flex flex-col sm:flex-row justify-center sm:items-end w-full gap-6 max-w-[500px]">
        <div class="flex flex-col">
          <label class="mb-3" for="startNumber">Vul jouw startnummer in:</label>
          <input class="rounded border px-3 py-2 flex-grow-1" v-model="userInputStartNumber" type="text"
                 id="startNumber"
                 placeholder="1234" required>
        </div>
        <button class="btn flex-grow-1" type="submit">Zoeken</button>
      </div>
    </form>

    <div class="py-14 px-8 bg-gray-100 w-[calc(100%+80px)] -ml-[40px]" v-if="submissions.length > 0 || startNumber">
      <!--      <div class="h-[1px] w-[calc(100%+80px)] -ml-[40px] my-20 border"></div>-->
      <div v-if="submissions.length > 0">
        <h3 class="text-center mb-10">Jouw Midwinter Marathon aanmoedigingsvideos</h3>
        <div class="flex flex-col gap-16" :class="{ 'single-video-grid': submissions.length === 1 }">
          <div class="flex flex-col justify-center items-center" v-for="(submission, index) in submissions"
               :key="index">
            <video class="w-full aspect-video bg-white rounded mb-6" :src="submission.video" controls></video>
            <button class="btn" @click="downloadVideo(submission.video)">
              <svg class="w-[16px]" viewBox="0 0 576 512">
                <path fill="white"
                      d="M528 288h-92.1l46.1-46.1c30.1-30.1 8.8-81.9-33.9-81.9h-64V48c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v112h-64c-42.6 0-64.2 51.7-33.9 81.9l46.1 46.1H48c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V336c0-26.5-21.5-48-48-48zm-400-80h112V48h96v160h112L288 368 128 208zm400 256H48V336h140.1l65.9 65.9c18.8 18.8 49.1 18.7 67.9 0l65.9-65.9H528v128zm-88-64c0-13.3 10.7-24 24-24s24 10.7 24 24-10.7 24-24 24-24-10.7-24-24z"/>
              </svg>
              <span>
              Downloaden
            </span>
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="startNumber" class="py-10">
        <h3 class="text-center">Helaas is er geen video voor je ingestuurd dit jaar. Dit doet echter niks af aan jouw
          fantastische prestatie! Gefeliciteerd!</h3>
      </div>
    </div>
  </div>
</template>

<script>
import {getSubmissionByStartNumber} from "@/firebase";

export default {
  name: 'videosPage',
  data() {
    return {
      eventId: 1314,
      submissions: [],
      startNumber: null,
      userInputStartNumber: '',
    };
  },
  mounted() {
    if (this.$route.query.start_number) {
      this.startNumber = this.$route.query.start_number;
      this.userInputStartNumber = this.startNumber;
      this.readSubmissions();
    }
  },
  methods: {
    async readSubmissions() {
      if (this.startNumber) {
        this.submissions = await getSubmissionByStartNumber(this.startNumber);
        console.log(this.submissions[0]);
      }
    },
    async searchVideo() {
      this.startNumber = this.userInputStartNumber;
      await this.readSubmissions();
    },
    downloadVideo(url) {
      window.open(url, '_blank');
    }
  },
};
</script>

<style scoped lang="scss">
</style>
