<template>

  <!-- Hero Section -->
  <section
    class="py-24 bg-cover bg-center text-white"
    :style="{ background: `linear-gradient(rgba(7, 30, 66, 0.55), rgba(7, 30, 66, 0.55)), url('/images/${eventId}/hero.webp') no-repeat center`, backgroundSize: 'cover' }"
  >
    <div class="container px-4 mx-auto">
      <div class="flex flex-col justify-center items-center">
        <img class="w-[82px] lg:w-[180px]" src="/images/all/logos/tracx-logo-white.svg" alt="tracx logo">
        <h1 class="mt-8 text-white lg:mt-12 lg:text-6xl"><span class="italic">TRACX</span> #CheerZone</h1>
        <p class="text-xl mb-8 lg:text-2xl">
          Support jouw hardloopheld met een persoonlijke videoboodschap <span class="inline-block">tijdens de Midwinter Marathon Apeldoorn.</span>
        </p>
        <a href="#form" class="btn">Video inzenden</a>
        <img class="mt-12 w-[82px] lg:w-[180px]" :src="'/images/logos/' + eventId + '/midwinter-logo.webp'" alt="Midwinter marathon logo">
      </div>
    </div>
  </section>

  <div class="container px-4 mx-auto">

    <!-- Video Upload Section -->
    <section>
      <div class="mt-8 flex justify-center lg:mt-16">
        <iframe class="rounded-lg w-full aspect-video max-w-[800px]"
                src="https://www.youtube.com/embed/lcRDqv4vvTA?si=d5plDO3cKd-xGL2Z" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen=""></iframe>
      </div>

      <div class="flex flex-col items-center my-10 lg:my-16">
        <h2 class="text-center lg:text-5xl lg:mb-8">Jouw Cheer Zone video</h2>
        <p class="text-center text-blue lg:text-xl max-w-[800px]">
          Op zondag 2 februari doet een recordaantal deelnemers mee aan de Midwinter Marathon in Apeldoorn. Of jij er
          als supporter nou wel of niet fysiek bij kan zijn: je kunt jouw persoonlijke videoboodschap hieronder
          inzenden, zodat deze live tijdens de race wordt afgespeeld op het grote scherm als jouw vriend, collega of
          familielid langs rent. Hoe gaaf is dat?!
        </p>
      </div>

      <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-8">
        <div class="rounded-lg border border-orange bg-white p-8 mb-10">
          <h3>Video aandachtspunten*</h3>
          <ul class="mb-6">
            <li style="color: red">Upload een video van maximaal 6 seconden</li>
            <li>Upload een video van maximaal 20Mb</li>
            <li>Helaas kunnen er geen video’s voor de kidsruns worden ingestuurd, aangezien dit parcours niet langs de
              TRACX Cheerzone komt.
            </li>
            <li>Pak de aandacht van de deelnemer door gelijk zijn naam om te roepen</li>
            <li>Upload je video horizontaal</li>
          </ul>
          <p>Wij doen ons best om zoveel mogelijk video’s te tonen aan de deelnemers. Het kan natuurlijk zijn dat
            het erg druk is op het moment dat jouw held door de TRACX Cheer Zone komt (en dus langs het scherm
            loopt), waardoor we helaas niet aan iedereen de persoonlijke video kunnen tonen. </p>
          <br>
          <p>Indien je ingezonden video te lang is, tonen we alleen de eerste 6 seconden.</p>
        </div>

        <template v-if="isSubmitted">
          <div><p class="text-orange font-semibold">Bedankt voor je (Cheer) video, zo haalt jouw hardloopheld zeker de finish!</p>
            <button class="btn inline-flex mt-3"  @click.prevent="refreshAndNavigate">Nog een video uploaden</button></div>

        </template>
        <template v-else>
          <div id="form" class="flex flex-col">
            <p class="font-semibold mb-6">Upload je video van maximaal 6 seconden, waarin je iemand aanmoedigt!</p>

            <!-- Upload Form -->
            <v-form ref="form" validate-on="submit" @submit.prevent="submitForm">
              <v-row>
                <v-col cols="12" class="!pb-0">
                  <v-file-input
                    v-model="video"
                    :rules="videoRules"
                    label="Upload je video (vereist)"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" class="!pb-0">
                  <v-text-field
                    v-model="name"
                    :rules="requiredRules"
                    :counter="10"
                    label="Naam deelnemer (vereist)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="!pb-0">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    :counter="10"
                    label="Jouw e-mailadres (vereist)"
                    class="no-message"
                  ></v-text-field>
                  <p class="leading-2 font-weight-light text-sm text-gray-600 px-4 mb-4">Zo kunnen we je een berichtje
                    sturen zodra je upload succesvol is</p>
                </v-col>
                <v-col cols="12" class="!pb-0">
                  <v-text-field
                    v-model="start_number"
                    :rules="requiredRules"
                    :counter="10"
                    label="Startnummer (vereist)"
                    class="no-message"
                  >
                  </v-text-field>
                  <p class="leading-2 font-weight-light text-sm text-gray-600 px-4 mb-4">Deze kan je ook vinden op <a
                    class="text-orange underline" target='blank' href='https://tracx.events/nl/events/1314'>https://tracx.events/nl/events/1314</a>
                  </p>
                </v-col>
                <v-col cols="12" class="!pb-0">
                  <v-checkbox
                    v-model="terms"
                    :rules="termsRules"
                    :counter="10"
                    label="Ik ga ermee akkoord dat mijn video op het grote scherm getoond wordt en dat deze door Sportunity voor marketingdoeleinden gebruikt kan worden."
                    class="no-message"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-btn class="btn mt-6" variant="flat" :ripple=false type="submit" ref="submitButton" :loading="loading">
                Versturen
              </v-btn>
            </v-form>
          </div>
        </template>
      </div>
    </section>
  </div>

  <!-- Map Section -->
  <section class="flex flex-col bg-blue mt-40 text-white lg:mt-72 pb-8">
    <div class="container px-4 mx-auto">
      <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-8">
        <div class="flex flex-col lg:order-2">
          <img class="block -mt-24 lg:-mt-40" alt="Route map" :src="'/images/' + eventId + '/map.webp'">
          <a class="block py-10 lg:mt-auto" href="https://tracx.events/" target="_blank">
            <img class="w-full max-w-[350px]" alt="Logo" :src="'/images/all/logos/tracx-cheerzone.webp'">
          </a>
        </div>

        <div class="flex flex-col lg:order-1 lg:mt-14">
          <h3 class="text-white">Support ook jouw held naar de finish line!</h3>
          <p class="text-gray-300 text-sm lg:text-base">Upload je video om je persoonlijke aanmoediging live op een van de grote schermen te tonen als jouw vriend of familielid door de TRACX Cheer Zone loopt tijdens de Midwinter Marathon Apeldoorn 2025.</p>
          <a class="py-10 block lg:mt-auto" href="https://sportunity.nu/" target="_blank">
            <img class="w-full max-w-[350px] object-contain" src="/images/all/logos/sportunity-powered-by.webp"
                 alt="Sportunity">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "@/firebase";
import {createSubmission, auth} from "@/firebase";
import {FFmpeg} from '@ffmpeg/ffmpeg';
import {fetchFile} from '@ffmpeg/util';


export default {
  name: 'formPage',
  data: () => ({
    eventId: 1314,
    isSubmitted: false,
    loading: false,
    requiredRules: [
      value => {
        if (value) {
          return true
        }

        return 'Dit veld is verplicht';
      }
    ],
    emailRules: [
      value => {
        if (value) {
          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
            return true;
          } else {
            return 'Dit is geen geldig e-mailadres';
          }
        }

        return 'Dit veld is verplicht';
      }
    ],
    termsRules: [
      value => {
        if (value) {
          return true
        }

        return 'Dit veld is verplicht';
      }
    ],
    videoRules: [
      value => {
        // return !!value;
        if (value && value.length > 0) {

          // Only one video is allowed
          const videoFile = value[0];

          // Check of het bestandstype een video is
          const isVideo = videoFile.type.startsWith('video/');
          if (!isVideo) return 'Het bestand moet een video zijn.';

          // Controleer de duur van de video
          return new Promise(resolve => {
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = () => {
              window.URL.revokeObjectURL(video.src);
              const duration = video.duration;
              // Controleer of de duur binnen 6 seconden is
              if (duration > 8) {
                resolve('De video mag niet langer zijn dan 6 seconden.');
              } else {
                // Controleer de bestandsgrootte (maximaal 20 MB) alleen als de duur geldig is
                const maxFileSize = 20 * 1024 * 1024; // 20 MB in bytes
                if (videoFile.size > maxFileSize) {
                  resolve('De video mag niet groter zijn dan 20 MB.');
                } else {
                  resolve(true);
                }
              }
            };

            video.onerror = () => {
              resolve('Kan de video niet valideren. Probeer een andere video');
            };

            video.src = URL.createObjectURL(videoFile);

            return true;
          });
        }

        return 'Dit veld is verplicht';
      }
    ],
    name: "",
    start_number: "",
    video: null,
    image: null,
    email: '',
    terms: false
  }),
  methods: {
    refreshAndNavigate() {
      // Verwijs naar de huidige URL met een force reload
      window.location.href = '/midwinter/upload#form';
      window.location.reload();
    },
    async submitForm() {
      this.loading = true;
      const {valid} = await this.$refs.form.validate();

      if (valid) {

        // Create FormData to send file and additional data
        const formData = {
          name: this.name,
          start_number: this.start_number,
          video: this.video,
          email: this.email,
          terms: this.terms
        }

        // example file upload
        const file = formData.video;
        const fileName = auth.currentUser.uid + '-' + file.name;
        const storageRef = ref(storage, 'uploads/' + this.eventId + '/' + fileName);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            console.log("Uploading...", (snapshot.bytesTransferred / snapshot.totalBytes) * 100 + "%");
          },
          (error) => {
            console.error("Upload failed:", error);
            this.loading = false;
          },
           () => {
            getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {

              // We need the url
              formData.video = downloadURL;

              const result = await createSubmission(formData);
              if(result) {
                this.isSubmitted = true;
              }

              this.loading = false;
            });
          }
        );
      } else {
        this.loading = false;
      }
    },
    // Not being used right now
    async compressVideo(file) {
      try {
        const ffmpeg = new FFmpeg();
        await ffmpeg.load();

        // Upload bestand naar FFmpeg
        await ffmpeg.writeFile(file.name, await fetchFile(file));

        // Comprimeer de video (bijvoorbeeld: lagere resolutie en bitrate)
        const outputFilename = 'compressed.mp4';
        await ffmpeg.exec([
          '-i',
          file.name,
          '-vf',
          'scale=1280:-2', // Stel de breedte in op 1280px en behoud de aspectratio
          '-b:v',
          '1M', // Stel de bitrate in op 1 Mbps
          outputFilename
        ]);

        // Lees het gecomprimeerde bestand uit de virtuele FS
        const data = await ffmpeg.readFile(outputFilename);

        // Maak een Blob en File van de data
        const compressedBlob = new Blob([data.buffer], {type: 'video/mp4'});
        return new File([compressedBlob], outputFilename, {
          type: 'video/mp4',
          lastModified: Date.now(),
        });
      } catch (e) {
        console.log(e);

        return file;
      }
    }
  },
};
</script>


<style lang="scss">
body {
  background: #FAFBFC;
}
</style>
